import firebase from "firebase/app";
import "firebase/database";
import 'firebase/storage';
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCQbir4HSRDbc9Hs-vQpzbIeka0XH1xk-M",
  authDomain: "belgravia-2022-ec602.firebaseapp.com",
  databaseURL: "https://belgravia-2022-ec602-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "belgravia-2022-ec602",
  storageBucket: "belgravia-2022-ec602.appspot.com",
  messagingSenderId: "524649806832",
  appId: "1:524649806832:web:80035c0ed8f8064240bb35",
  measurementId: "G-TPGS1H4S01"
};

const firebaseInit = firebase.initializeApp(firebaseConfig);
const fireDb = firebaseInit.database().ref();
const storage = firebase.storage();
const auth = firebase.auth();

const logInWithEmailAndPassword = async (email, password) => {
  try {
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (first_name, last_name, email, password, company_name) => {
  try {
    const res = await firebase.auth().createUserWithEmailAndPassword(email, password);
    const user = res.user;
    
    const user_id = fireDb.child("user_profile").push().getKey();    
    fireDb.child("user_profile").child(user_id).set({
      uid: user.uid,
      first_name : first_name,
      last_name : last_name,
      company_name : company_name,
    });
    return res;
  } catch (err) {
    alert(err.message);
  }
};

export  {
  storage,
  auth, 
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  fireDb as default
}
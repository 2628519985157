import React, { useState, useEffect, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import "./AddEdit.css";
import fireDb from "../firebase";
import { storage } from "../firebase";
import { toast } from "react-toastify";
import Form from 'react-bootstrap/Form'

import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax 
mapboxgl.accessToken = 'pk.eyJ1IjoiZGFyYWJhc2UiLCJhIjoiY2wwY205dnY4MDBrZzNjazZmMW44ZXFpeiJ9.bIKVM_2--Tej47Ly_UjqdQ';

const initialState = {
  name: "",
  status: "",
  image: "",
  lat: 0,
  lng: 0,
  description: "",  
  category: "",
  type: "",
  icon: "",
  url: "",
  ctaText: "",
  instagram: "",
  allowVote: true,
  markerWidth: 0,
  markerHeight: 0,
};

const AddEdit = () => {
  const [state, setState] = useState(initialState);
  const [data, setData] = useState({});

  const allInputs = { imgUrl : '' };
  const [imageAsFile, setImageAsFile] = useState('');
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);

  const { name, status, image, lat, lng, url, description, ctaText, instagram, category, type, icon, allowVote, markerWidth, markerHeight } = state;

  const history = useHistory();

  const { id } = useParams();

  //Map constants
  const mapContainer = useRef(null);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    fireDb.child("contacts").on("value", (snapshot) => {
      if (snapshot.val() !== null) {
        setData({ ...snapshot.val() });
      } else {
        setData({});
      }
    });

    return () => {
      setData({});
    };
  }, [id]);

  useEffect(() => {
    if (id) {
      setState({ ...data[id] });    
    } else {
      setState({ ...initialState });      
    }

    return () => {
      setState({ ...initialState });
    };
  }, [id, data]);

  //Initialize map when component mounts
  useEffect(() => {

    var map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [lat, lng],
      zoom: 16
    });
    
    var marker = new mapboxgl.Marker({
      draggable: true
    })
    .setLngLat([lng, lat])
    .setPopup(
      new mapboxgl.Popup({ offset: 25 }) // add popups
        .setHTML(
          `<p>${name}</p>`
        )
    )
    .addTo(map);

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    marker.on('dragend', () => {      
      var lngLat = marker.getLngLat();
      var latitude = lngLat.lat.toFixed(4);
      var longitude = lngLat.lng.toFixed(4);
      
      setState(prevObject => ({...prevObject, lat: latitude}))
      setState(prevObject => ({...prevObject, lng: longitude}))
    });

    setMap(map);
    setMarker(marker);    

    // Clean up on unmount
    return () => map.remove();
  }, []);

  useEffect(() => {
    if(id){
        if(map && data[id]){

          map.on('load', () => {      
            var latitude = data[id].lat;
            var longitude = data[id].lng;

            const center = new mapboxgl.LngLat(longitude, latitude);
            
            // Center the map
            //map.setCenter(center);            

            // Animate the map
            map.flyTo({
              center: center,
              essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });

            marker.setLngLat([longitude, latitude]);

          });
        }
    }
  }, [id, data]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleVoteChange = (e) => {
    const { name, checked } = e.target;
    setState({ ...state, [name]: checked });
  };

  const handleFileChange = (e) => {
    const image = e.target.files[0];
    setImageAsFile(imageFile => (image))
    setImageAsUrl({ imgUrl: URL.createObjectURL(e.target.files[0]) })
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !status) {
      toast.error("Please provide value in each input field");
    } else {
      if (!id) {

        const contact_id = fireDb.child("contacts").push().getKey();
        if(imageAsFile !== ''){
          var ext = (imageAsFile.type).split("/")[1];
          var finalImageName = contact_id+"."+ext;
          const uploadTask = storage.ref(`/images/${contact_id}/${finalImageName}`).put(imageAsFile);
          uploadTask.on('state_changed', 
            (snapShot) => {
              console.log(snapShot)
              toast.warn("Please wait...");
            }, (err) => {
              console.log(err)
            }, () => {

              storage.ref(`images/${contact_id}`).child(finalImageName).getDownloadURL()
              .then(fireBaseUrl => {
                //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}));
                state.image = fireBaseUrl;
                fireDb.child("contacts").child(contact_id).set(state);
                toast.success("Contact Added Successfully");
                setTimeout(() => history.push("/"), 500);
              });

          });
        } else {          
          fireDb.child("contacts").child(contact_id).set(state);
          toast.success("Contact Added Successfully");
          setTimeout(() => history.push("/"), 500);
        }

      } else {

        if(imageAsFile !== ''){
          var ext = (imageAsFile.type).split("/")[1];
          var finalImageName = id+"."+ext;
          const uploadTask = storage.ref(`/images/${id}/${finalImageName}`).put(imageAsFile);
          uploadTask.on('state_changed', 
            (snapShot) => {
              console.log(snapShot)
              toast.warn("Please wait...");
            }, (err) => {
              console.log(err)
            }, () => {

              storage.ref(`images/${id}`).child(finalImageName).getDownloadURL()
              .then(fireBaseUrl => {
                //setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
                state.image = fireBaseUrl;
                fireDb.child(`contacts/${id}`).set(state);
                toast.success("Contact Updated Successfully");
                setTimeout(() => history.push("/"), 500);
              });

          });
        } else {
          fireDb.child(`contacts/${id}`).set(state);
          toast.success("Contact Updated Successfully");
          setTimeout(() => history.push("/"), 500);
        }

        // fireDb.child(`contacts/${id}`).set(state, (err) => {
        //   if (err) {
        //     toast.error(err);
        //   } else {
        //     toast.success("Contact Updated Successfully");
        //   }
        // });
      }

    }
  };
  return (
    <div style={{ marginTop: "50px" }}>
      <h4>{name}</h4>
      <form
        style={{
          margin: "auto",
          padding: "30px",
          alignContent: "center",
          textAlign: "left",
        }}
        onSubmit={handleSubmit}
      >
        <div className = "row">
            <div className = "col-md-6">

              <div className = "form-area">
                <label htmlFor="file">Logo</label>      
                <br/>
                <input
                  accept="image/*"
                  type="file"
                  id="file"
                  name="file"          
                  onChange={handleFileChange}
                />
                {(imageAsUrl.imgUrl !=='' || image !=='') &&
                  <>
                    <p>
                      <img style={{width: 300}} src={imageAsUrl.imgUrl || image} />            
                    </p>
                  </>
                }
              </div>
              {/* <br></br> */}
              <div className = "form-area">
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Your Name..."
                  value={name || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="description">Description</label>
                <textarea
                  type="text"
                  id="description"
                  name="description"
                  placeholder="Description"
                  value={description || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="url">URL</label>
                <input
                  type="text"
                  id="url"
                  name="url"
                  placeholder="URL"
                  value={url || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="ctaText">CTA Text</label>
                <input
                  type="text"
                  id="ctaText"
                  name="ctaText"
                  placeholder="CTA Text"
                  value={ctaText || ""}
                  onChange={handleInputChange}
                />
              </div>
              
              <div className = "form-area">
                <label htmlFor="category">Category</label>
                <select name="category" value={category} onChange={handleInputChange}>
                  <option>Select Category</option>
                  <option value="shopping">Shopping</option>
                  <option value="dining">Dining</option>
                  <option value="hotel">Hotel</option>
                  <option value="beauty">Beauty and Wellbeing</option>
                  <option value="bubble">Bubble</option>
                  <option value="quiz">Quiz</option>
                </select>
              </div>

              <div className = "form-area">
              <label htmlFor="type">Type</label>
              <select name="type" value={type} onChange={handleInputChange}>
                <option>Select Type</option>
                <option value="standard">Standard</option>
                <option value="bubble">Bubble</option>
                <option value="quiz">Quiz</option>
              </select>
              </div>

              <div className = "form-area">
              <label htmlFor="icon">Icon</label>
              <select name="icon" value={icon} onChange={handleInputChange}>
                <option>Select Icon</option>
                <option value="shopping">Shopping</option>
                  <option value="dining">Dining</option>
                  <option value="hotel">Hotel</option>
                  <option value="beauty">Beauty and Wellbeing</option>
                  <option value="360ey">360: Eccleston Yards</option>
                  <option value="360es">360: Elizabeth Street</option>
                  <option value="360ms">360: Motcomb Street</option>
                  <option value="360pr">360: Pimlico Road</option>
                  <option value="quizey">Quiz: Eccleston Yards</option>
                  <option value="quizes">Quiz: Elizabeth Street</option>
                  <option value="quizms">Quiz: Motcomb Street</option>
                  <option value="quizpr">Quiz: Pimlico Road</option>
              </select>
              </div>

              <div className = "form-area">
                <label htmlFor="instagram">Instagram URL</label>
                <input
                  type="text"
                  id="instagram"
                  name="instagram"
                  placeholder="Instagram"
                  value={instagram || ""}
                  onChange={handleInputChange}
                />
              </div>

              <div className = "form-area"> 
                <Form.Check 
                  type="switch"
                  id="allowVote"
                  name="allowVote"
                  label="Allow Voting"
                  checked={allowVote}
                  onChange={handleVoteChange}
                />
              </div>

              <div className = "form-area">
                <label htmlFor="status">Status</label>
                <select name="status" value={status} onChange={handleInputChange}>
                  <option>Select Status</option>
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
              
              {/* <label htmlFor="status">Status</label>
              <input
                type="text"
                id="status"
                name="status"
                placeholder="Your Status..."
                value={status || ""}
                onChange={handleInputChange}
              /> */}
              <input type="submit" value={id ? "Update" : "Save"} />

          </div>

          <div className = "col-md-6">

              <div ref={mapContainer} className="map-container" />

              <div className = "mapcoord">
                {lat} : {lng}
              </div>

              <br></br>

              <div className = "form-area">
                <label htmlFor="lat">Lat</label>
                <input
                  type="text"
                  id="lat"
                  name="lat"
                  placeholder="Lat"
                  value={lat || ""}
                  onChange={handleInputChange}
                />
              </div>
              <div className = "form-area">
                <label htmlFor="lng">Lng</label>
                <input
                  type="text"
                  id="lng"
                  name="lng"
                  placeholder="Lng"
                  value={lng || ""}
                  onChange={handleInputChange}
                />
              </div>

              <hr></hr>
              <div className="devSection">
                <p>For Developers ONLY</p>
                <div className = "form-area">
                  <label htmlFor="markerWidth">Marker Width</label>
                  <input
                    type="number"
                    id="markerWidth"
                    name="markerWidth"
                    placeholder="Width"
                    value={markerWidth || ""}
                    onChange={handleInputChange}
                  />
                </div>
                <div className = "form-area">
                  <label htmlFor="markerHeight">Marker Height</label>
                  <input
                    type="number"
                    id="markerHeight"
                    name="markerHeight"
                    placeholder="Height"
                    value={markerHeight || ""}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

          </div>

          <div className = "col-md-6">
            
          </div>

        </div>

      </form>
    </div>
  );
};

export default AddEdit;

import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams, useLocation } from "react-router-dom";
import "./Login.css";
import { auth, storage, logInWithEmailAndPassword, registerWithEmailAndPassword } from "../firebase";
import { toast } from "react-toastify";

const initialState = {
  email: "",
  password: ""  
};

const Login = () => {
  const [state, setState] = useState(initialState);
  const { email, password } = state;

  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    console.log("authLogin: ", auth.currentUser);
    if(auth.currentUser !== null){
      history.push("/home");
    }
  }, [auth]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email && !password) {
      toast.error("Please enter your email and password!");
    } else {
      const loginResp = await logInWithEmailAndPassword(email, password);
      if(loginResp !== undefined){
        history.push("/home");
      }
      //await registerWithEmailAndPassword('Nitin', 'Surani', email, password, 'PW');
    }
  };

  return (
    <div style={{ marginTop: "50px" }}>
      <form
        style={{
          margin: "auto",
          padding: "30px",
          alignContent: "center",
        }}
        onSubmit={handleSubmit}
      >
        <div className = "row">
            <div className = "col-md-12">

              <div className = "col-md-4 offset-md-4">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  placeholder="Your Email..."
                  value={email || ""}
                  onChange={handleInputChange}
                />                            
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Your Password..."
                  value={password || ""}
                  onChange={handleInputChange}
                />                            

                <input type="submit" value="Login" />

            </div>
          </div>
        
        </div>

      </form>
    </div>
  );
};

export default Login;
import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "./Header.css";
import { auth } from "../firebase";

const Header = (currentAuth) => {
  const [activeTab, setActiveTab] = useState("Login");
  const [isAuth, setAuth] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useState("");

  const history = useHistory();

  useEffect(() => {
    if (location.pathname === "/" || location.pathname === "/login") {
      setActiveTab("Login");
    } else if (location.pathname === "/home") {
      setActiveTab("Home");
    } else if (location.pathname === "/add") {
      setActiveTab("AddContact");
    }    
    
    if(auth.currentUser !== null){
      setAuth(true);
    }
  }, [location, auth]);
 
  const handleSubmit = (e) => {
    e.preventDefault();
    history.push(`/search?name=${search}`);
    setSearch("");
  };

  const logout = () => {
    auth.signOut().then(() => {
      setAuth(false);
      history.push("/login")
    }).catch((error) => {
      console.log(error);
    });  
  };
  
  const register = () => {
      history.push("/register");
  };

  return (
    <div className="header">
      <p className="logo">Map Points</p>
      <div className="header-right">
        {/* <form onSubmit={handleSubmit} style={{ display: "inline" }}>
          <input
            type="text"
            className="inputField"
            placeholder="Search Name ..."
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
        </form> */}
        {isAuth ? 
        <>
          <Link to="/home">
            <p
              className={`${activeTab === "Home" ? "active" : ""}`}
              onClick={() => setActiveTab("Home")}
            >
              Home
            </p>
          </Link>
          <Link to="/add">
            <p
              className={`${activeTab === "AddContact" ? "active" : ""}`}
              onClick={() => setActiveTab("AddContact")}
            >
              Add POI
            </p>
          </Link>
            <p
              className={`${activeTab === "Logout" ? "active" : ""}`}
              onClick={() => logout()}
            >
              Logout
            </p>
        </>
          :
          null
        //   <>
        //   <Link to="/">
        //     <p
        //       className={`${activeTab === "Login" ? "active" : ""}`}
        //       onClick={() => setActiveTab("Login")}
        //     >
        //       Login
        //     </p>
        //   </Link>
        //   <Link to="/register">
        //     <p
        //       className={`${activeTab === "Register" ? "active" : ""}`}
        //       onClick={() => register()}
        //     >
        //       Register
        //     </p>
        //   </Link>
        // </>
        }

      </div>
    </div>
  );
};

export default Header;

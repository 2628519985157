import React, { Component } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./App.css";
import About from "./pages/About";
import AddEdit from "./pages/AddEdit";
import Home from "./pages/Home";
import View from "./pages/View";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./components/Header";
import Search from "./pages/Search";
import Login from "./pages/Login";
import Register from "./pages/Register";

import { auth } from "./firebase";

//console.log("auth", auth.currentUser);

/**
 * [RequireAuth Create secure route]
 * @param {@View} ComposedComponent [Component name to be pass here]
 */
 function RequireAuth(ComposedComponent) {

  class RequireAuth extends Component {  
    state = {
      isAuthenticated: (auth.currentUser !== null ? true : false)
    }

    // Push to login route if not authenticated on mount
    async componentDidMount() {        
      if(!this.state.isAuthenticated) {
          await this.props.history.push("/login");
      }
    }

    // Push to login route if not authenticated on update
    componentDidUpdate(nextProps) {
      if(!this.state.isAuthenticated) {
          this.props.history.push("/login");
      }        
    }

    // Otherwise render the original component
    render() {
      return <ComposedComponent {...this.props}/>
    }  
  }
  return RequireAuth;  
}

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <ToastContainer position="top-center" />
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/register" component={Register} /> */}
          <Route exact path="/home" component={RequireAuth(Home)} />
          <Route path="/add" component={RequireAuth(AddEdit)} />
          <Route path="/update/:id" component={RequireAuth(AddEdit)} />
          {/* <Route path="/view/:id" component={RequireAuth(View)} /> */}
          {/* <Route path="/search" component={RequireAuth(Search)} /> */}
        </Switch>
      </div>
    </BrowserRouter>
  );
}

export default App;
